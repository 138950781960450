import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ConnectToPGEButton from "../ConnectToPGEButton";
import Container from "@mui/material/Container";
import React, {useState} from "react";


const EVChargingCalculator: React.FC = () => {
    const [electricityRate, setElectricityRate] = useState<number>(0.12);
    const [chargingTime, setChargingTime] = useState<number>(0);
    const [evEfficiency, setEVefficiency] = useState<number>(4);
    const [chargingCost, setChargingCost] = useState<number>(0);

    const calculateChargingCost = () => {
        const cost = (electricityRate * chargingTime) / evEfficiency;
        setChargingCost(cost);
    };

    return (
        <Container maxWidth="sm" style={{marginTop: '40px'}}>
            <Typography variant="h4" gutterBottom>
                EV Charging Cost Calculator
            </Typography>
            <div>
                <TextField
                    type="number"
                    label="Electricity Rate (per kWh in $)"
                    value={electricityRate}
                    onChange={(e) => setElectricityRate(parseFloat(e.target.value))}
                    fullWidth
                    margin="normal"/>
            </div>
            <div>
                <TextField
                    type="number"
                    label="Charging Time (hours)"
                    value={chargingTime}
                    onChange={(e) => setChargingTime(parseFloat(e.target.value))}
                    fullWidth
                    margin="normal"/>
            </div>
            <div>
                <TextField
                    type="number"
                    label="EV Efficiency (miles per kWh)"
                    value={evEfficiency}
                    onChange={(e) => setEVefficiency(parseFloat(e.target.value))}
                    fullWidth
                    margin="normal"/>
            </div>
            <Button variant="contained" color="primary" onClick={calculateChargingCost} style={{marginTop: '20px'}}>
                Calculate
            </Button>
            {chargingCost > 0 && (
                <div style={{marginTop: '20px'}}>
                    <Typography variant="h6" gutterBottom>
                        Charging Cost:
                    </Typography>
                    <Typography variant="body1">${chargingCost.toFixed(2)}</Typography>
                </div>
            )}

        </Container>
    )
}
export default EVChargingCalculator;