import React from 'react';
import {
    Button,
    Container,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
interface WelcomePageProps {
    onManualAddClick: () => void;
}
const WelcomePage: React.FC<WelcomePageProps> = ({onManualAddClick}) => {
    const handleManualAddClick = () => {
        // Handle manual add click
    };

    const handleConnectClick = () => {
        // Handle connect to PGE click
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '2rem' }}>
                <Grid container spacing={3} direction="column" alignItems="center">
                    <Grid item>
                        <Typography variant="h4" align="center" gutterBottom>
                            Welcome to EV Charging Cost Calculator
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" align="center" paragraph>
                            Calculate the cost to charge your electric vehicle based on your usage.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={onManualAddClick}
                        >
                            Manually Add Electric Usage
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" align="center" paragraph>
                            - or -
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleConnectClick}
                        >
                            Connect to PGE
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default WelcomePage;
