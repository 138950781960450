import React, {useState} from 'react';
import WelcomePage from "./components/WelcomePage";
import ManualComputation from "./components/ManualComputation";


const App = () => {

    const [setManualComputation, setShowManualComputation] = useState(false)
    const handleManualAddClick = () => {
        setShowManualComputation(true)
    }
    return (
        <div>
            {
                setManualComputation ? (
                    <ManualComputation />
                ): (
                    <WelcomePage onManualAddClick={handleManualAddClick}/>
                )
            }

        </div>

    );
};

export default App


